import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import * as S from './WidgetDemo.style'

interface Props {
  match: any
}

const WidgetDemo: React.FC<Props> = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', 'chatbot-initials-script')

    if (window.location.hostname === 'localhost') {
      script.setAttribute('src', 'http://localhost:3030/script-chatbot.js')
      script.setAttribute('data-server-url', 'http://localhost:8080/api')
      script.setAttribute('data-bot-id', '6cb2c2a9-c0ce-47fa-9540-ef5828a7fe1e')
    } else {
      script.setAttribute('src', 'https://aime-prod.botscrew.net/widget/script-chatbot.js')
      script.setAttribute('data-server-url', 'https://aime-prod.botscrew.net/api')
      script.setAttribute('data-bot-id', '1ba527e9-5d3f-4538-b7c6-f6b83d083f7f')
    }

    script.setAttribute('embedded', 'true')
    script.setAttribute('placeholder-id', 'chatbot-container')
    script.setAttribute('embedded-width', '100%25')
    script.setAttribute('embedded-height', '100%25')

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <S.WidgetDemo>
      <S.Header>
        <S.SecondaryButton id="reset-chat-button">Reset chat</S.SecondaryButton>
      </S.Header>
      <S.Body>
        <div
          id="chatbot-container"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
        ></div>
      </S.Body>
    </S.WidgetDemo>
  )
}

export default withRouter(WidgetDemo)
